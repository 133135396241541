.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  font-size: 1.25rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(7, 2.5rem);
  grid-auto-rows: 2.5rem;
  place-items: center;
}

.heading {
  font-family: Montserrat;
  /* font-weight: 200; */
  text-transform: uppercase;
  font-size: 0.8rem;
}

.ringsIcon,
.ringsIcon > svg {
  width: 1.5em;
}

.ringsIcon > svg > * {
  stroke: var(--PEACH);
}

.weekDay {
  opacity: 60%;
}
