.sectionContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 1rem;
}

.text {
  margin: 1rem 0 1.5rem 0;
  text-align-last: start;
  text-align: start;
}

.fields {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  width: 100%;
}

.carousel,
.pictures,
.picWrapper,
.pic {
  border-radius: 1rem;
  overflow: hidden;
}

.carousel_desktop {
  aspect-ratio: 0.5614034347796747;
  max-height: 90vh;
}

.pictures {
  margin: 2rem 0;
}

.picWrapper {
  height: 100%;
}

.pic {
  height: 100%;
}
