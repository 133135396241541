.title {
  flex-shrink: 0;
}

.sectionContent {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin-top: 1rem;
}

.picture {
  aspect-ratio: 4 / 3;
  background-image: url("./res/villa_parravicino.jpg");
  background-size: cover;
  border-radius: 1rem;
  box-sizing: border-box;
  width: 100%;
}

.text {
  align-items: center;
  color: var(--DARK-GREEN);
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 0.5rem;
}

.addressGrid {
  align-items: center;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr min-content 1fr;
  width: 100%;
}

.addressStack {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.street {
  text-align: end;
}

.city {
  text-align: start;
}
