.sectionContent {
  margin: 1em 0;
}

.paperContent {
  color: var(--DARK-GREEN);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1em;
}

.paper {
  margin: 1.5em 0;
}

.divider {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.contactsRow {
  align-items: center;
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.contactsStack,
.addressStack {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.addressStack {
  gap: 0.5rem;
  width: 100%;
  margin-top: 0.5rem;
}

.stackItem {
  display: flex;
  align-items: center;
  gap: 0.25em;
}

.footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 0.75em;
  gap: 0.125rem;
  justify-content: flex-end;
}
