.root {
  gap: 1rem 0;
  justify-content: center;
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
}

.calendar {
  margin-block-start: -0.5rem;
}
