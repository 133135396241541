.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--PEACH);
}

.value {
  font-size: 2rem;
  font-weight: 500;
}
