.root {
  display: contents;
}

.right .image {
  padding-inline-start: 1rem;
}

.left .image {
  margin-inline-start: auto;
  padding-inline-end: 1rem;
}

.left,
.right {
  align-items: center;
  display: flex;
  overflow: hidden;
}

.left {
  border-inline-end: 1px solid var(--GREEN);
}

.right {
  border-inline-start: 1px solid var(--GREEN);
}
