.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  gap: 0.125rem;
  justify-content: flex-end;
}

.dialogText {
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
}
