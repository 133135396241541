.sectionContent {
  /* Local variables - start */
  --paper-height: 250px;
  --half-paper-height: calc(var(--paper-height) / 2);
  --envelope-height: calc(1.4 * var(--paper-height) + 1rem);
  /* Local variables - end */
  margin: 1rem 0;
}

.paperContent {
  align-items: center;
  box-sizing: border-box;
  color: var(--DARK-GREEN);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem 1rem var(--half-paper-height) 1rem;
  row-gap: 1rem;
}

.divider {
  align-items: flex-start;
  align-self: stretch;
  font-size: 0.75rem;
}

.paperWrapper {
  margin: 2rem 2rem calc(var(--envelope-height) - var(--paper-height) + 1rem)
    2rem;
  position: relative;
}

.paper {
  box-sizing: border-box;
  height: var(--paper-height);
  position: relative;
}

.paperWrapper::before,
.envelopeFront {
  left: -1rem;
  position: absolute;
  right: -1rem;
  top: 50%;
}

.paperWrapper::before {
  background-color: var(--PEACH);
  bottom: 0;
  content: "";
}

.envelopeFront {
  background-color: var(--ROSE);
  clip-path: polygon(0% 100%, 100% 100%, 100% 40%, 50% 65%, 0% 40%);
  height: var(--envelope-height);
  transform: translateY(-40%);
}
