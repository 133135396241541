.title {
  color: var(--DARK-GREEN);
  font-family: Charmonman;
}

.titleWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1rem;
}
